/* eslint-disable no-use-before-define */
import React, {useEffect, useState} from 'react';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {makeStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {get_objects_from_bucket} from "../utils";
import Loader from "./Loader";

const useStyles = makeStyles((theme) => ({
    root: {
        width: 500,
        '& > * + *': {
            marginTop: theme.spacing(3),
        },
    },
}));


export default function MultiSelectObject({bucket, id, setObjectList, objectList, objects, names, setNames}) {
    const classes = useStyles();
    const [selected, setSelected] = useState([]);
    const [options, setOptions] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [value, setValue] = useState(new Array(selected.length).fill(""));

    // useEffect(() => {
    //     get_objects_from_bucket(bucket).then(response => {
    //         console.log(bucket, response)
    //         if (response!==undefined) {
    //             setOptions(response)
    //             setLoaded(true)
    //         }
    //     })
    // }, [])

    useEffect(() => {
        if (objects !== undefined && objects.length > 0) {
            setOptions(objects)
            setLoaded(true)
        }
    }, [])

    useEffect(() => {
        let temp = names
        temp[id] = value
        setNames(temp)
    }, [value])

    useEffect(() => {
        //console.log(bucket, selected)
        let temp = objectList
        temp[id] = selected
        setObjectList(temp)
        //console.log(objectList)
        setValue(new Array(selected.length).fill([]));
    }, [selected])

    // useEffect(() => {
    //     setColumns([])
    //     setSelected([])
    // }, [options, setColumns])

    return (
        <div className={classes.root} style={{width: "100%", marginTop: "20px"}}>
            {!loaded && <Loader/>}


            {loaded && <Autocomplete
                multiple
                id="tags-outlined"
                options={options}
                getOptionLabel={(option) => option}
                defaultValue={[]}
                disableCloseOnSelect={true}
                filterSelectedOptions
                value={selected}

                onChange={
                    (event, value) => {
                        setSelected(value)
                    }
                }
                renderInput={(params) => (

                    <TextField

                        {...params}
                        variant="outlined"
                        label={bucket}
                    />
                )
                }
            />}
            {selected.length > 0 && selected.map((r, i) =>
                <TextField label={r} key={i} value={value[i] || ""}
                           style={{backgroundColor: "#f1f0f0", width: "95%"}}
                           variant="outlined"
                           onChange={(e) => {
                               let newValue = [...value]; // make a copy of the value array
                               newValue[i] = e.target.value; // change this to hold the new string
                               setValue(newValue);
                           }}


                />
            )}

        </div>
    );
}


