import React, {useEffect, useState} from 'react';
import {Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField} from '@material-ui/core';
import {get_buckets, get_objects_from_bucket, convert_to_table, redirect, convert_tables, getESDetails} from "../utils";
import MultiSelect from "./MultiSelect";
import {Alert, AlertTitle} from "@material-ui/lab";
import MultiSelectObject from "./MultiSelectObject";
import Loader from "./Loader";
import {useLocation, useParams} from "react-router-dom";

const formStyle = {
    margin: "1px",
    minWidth: "120",
}


export default function SelectObject({setDone, created, setCreated, data}) {
    const [buckets, setBuckets] = useState([])
    const [objects, setObjects] = useState([])
    const [objectList, setObjectList] = useState([])
    const [success, setSuccess] = useState(false)
    const [loaded, setLoaded] = useState(true)
    // const [data, setData] = useState([])
    const [names, setNames] = useState([])
    const [error, setError] = useState(false)
    const [errorName, setErrorName] = useState(false)
    const path = useParams();


    const sameName = (names) => {
        let seen = new Set();

        function helper(subList) {
            for (let item of subList) {
                if (Array.isArray(item)) {
                    if (helper(item)) {
                        return true;
                    }
                } else if (seen.has(item)) {
                    return true;
                } else {
                    seen.add(item);
                }
            }
            return false;
        }

        return helper(names);
    }

    const containsEmptyList = (names, objects) => {
        let flag = false
        for (let i = 0; i < objects.length; i++) {
            if (objects[i] !== []) {
                for (let j = 0; j < names[i].length; j++) {
                    if (names[i][j].length === 0 || names[i][j][0] === "") {
                        flag = true
                        break
                    }
                }
            }
        }
        return flag
    }

    const convertFiles = () => {
        //console.log("result", objectList, names)
        if (containsEmptyList(names, objectList)) {
            setError(true)
        } else if (sameName(names)) {
            setErrorName(true)
        } else {
            setError(false)
            setErrorName(false)
            setLoaded(false)
            //console.log(objectList, names, buckets)
            convert_tables(objectList, names, buckets,  localStorage.getItem('qr_actual_token'))
                .then(response => {
                    //console.log(response)
                    let temp = created
                    temp.push(response)
                    setCreated(prevCreated => [...prevCreated, response]);
                    setLoaded(true)
                    setSuccess(true)
                })
        }
    }




    const formatOutput = (list_names, objects) => {
        let result = []
        let counter = 0
        for (let i = 0; i < list_names.length; i++) {
            if (objects[i].length > 0) {
                result[counter] = {"name": list_names[i], "objects": objects[i]}
                counter++
            }
        }
        return result
    }

    useEffect(() => {
        console.log(names)
    }, [names])

    const handleSubmit = () => {

        console.log("result", formatOutput(buckets, objectList))
        setDone(true)
    }


    useEffect(() => {
        if (data != null) {
            let bucket_list = []
            for (let i = 0; i < data.length; i++) {
                console.log(data[i])
                let flag = false
                for (let j = 0; j < bucket_list.length; j++) {
                    if (bucket_list[j].name === data[i].bucket_name) {
                        bucket_list[j].objects.push(data[i].object_name)
                        flag = true
                        break
                    }
                }
                if (flag === false) {
                    bucket_list.push({
                        "name": data[i].bucket_name,
                        "objects": [data[i].object_name]
                    })
                }
            }
            setBuckets(bucket_list)
        }


    }, [data])


    return (
        <Paper style={{color: "gray", width: "100%", marginTop: "10px"}}>
            {buckets.length > 0 && buckets.map((res, id) =>
                <MultiSelectObject objectList={objectList}
                                   names={names} setNames={setNames}
                                   setObjectList={setObjectList} key={id}
                                   bucket={res['name']} objects={res['objects']} id={id}/>
            )}
            {!loaded && <Loader/>}
            {data === null && <h3>No objects</h3>}
            {data != null && <>

                <Grid item sx={12}>
                    <Button variant="contained" color="secondary" style={{margin: "20px"}}
                            onClick={convertFiles}>Convert to table</Button>
                </Grid></>}
            {success && <Alert style={{marginTop: 10}} severity="success">
                <AlertTitle>Success</AlertTitle>
                The tables have been created — <strong>check them out!</strong>
            </Alert>}
            {error && <Alert onClose={() => {
                setError(false)
            }} severity="error"
                             style={{width: "95%", marginTop: "5px"}}>
                {"Please fill in all the text boxes with different names "}
            </Alert>}
            {errorName && <Alert onClose={() => {
                setErrorName(false)
            }} severity="error"
                                 style={{width: "95%", marginTop: "5px"}}>
                {"Please use different names for the tables "}
            </Alert>}


        </Paper>)
}