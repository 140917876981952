import React, {useEffect, useState} from "react";
import {
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    Grid,
    TextField,
} from "@material-ui/core";
import {getColumns} from "../utils";

const formStyle = {
    margin: "1px",
    minWidth: "120",
};

const LogicalOperatorSelect = ({operator, setOperator}) => (
    <FormControl fullWidth variant="outlined" style={formStyle}>
        <InputLabel htmlFor="logical-operator-label">Operator</InputLabel>
        <Select
            style={{width: "100px"}}
            labelId="logical-operator-label"
            label="Operator"
            value={operator}
            onChange={(e) => setOperator(e.target.value)}
        >
            <MenuItem value="AND">AND</MenuItem>
            <MenuItem value="OR">OR</MenuItem>
            <MenuItem value="AND not">AND ΝΟΤ</MenuItem>
        </Select>
    </FormControl>
);

const ConditionSelect = ({condition, setCondition}) => (
    <FormControl fullWidth variant="outlined" style={formStyle}>
        <InputLabel htmlFor="table-label2">Condition</InputLabel>
        <Select
            style={{width: "150px"}}
            labelId="table-label2"
            label="Condition"
            value={condition}
            onChange={(e) => setCondition(e.target.value)}
        >
            <MenuItem value="=">Equal to</MenuItem>
            <MenuItem value=">">Greater than</MenuItem>
            <MenuItem value="<">Less than</MenuItem>
            <MenuItem value="= DATE ">on (DATE)</MenuItem>
            <MenuItem value="> DATE ">After (DATE) </MenuItem>
            <MenuItem value="< DATE ">Before (DATE) </MenuItem>
        </Select>
    </FormControl>
);

const ColumnSelect = ({columns, filterColumn, setFilterColumn}) => (
    <FormControl fullWidth variant="outlined" style={formStyle}>
        <InputLabel htmlFor="table-label1">Column</InputLabel>
        <Select
            style={{width: "150px"}}
            labelId="table-label1"
            label="Column"
            value={filterColumn}
            onChange={(e) => setFilterColumn(e.target.value)}
        >
            {columns.length > 0 &&
            columns.map((column, i) => (
                <MenuItem value={column} key={i}>
                    {column}
                </MenuItem>
            ))}
        </Select>
    </FormControl>
);

const ValueInput = ({value, setValue}) => (
    <FormControl fullWidth variant="outlined" style={formStyle}>
        <TextField
            style={{width: "150px"}}
            variant="outlined"
            label="Value"
            value={value}
            onChange={(e) => setValue(e.target.value)}
        ></TextField>
    </FormControl>
);

export default function Filter({table, where, setWhere, count, id, showLogicalOperator = false,token}) {
    const [filterColumn, setFilterColumn] = useState('')
    const [condition, setCondition] = useState('')
    const [value, setValue] = useState('')
    const [columns, setColumns] = useState([])

    const [operator, setOperator] = showLogicalOperator === false ? useState("") : useState("AND");

    useEffect(() => {
        let tempWhere = where;
        tempWhere[id] = {operator, filterColumn, condition, value};
        setWhere(tempWhere);
    }, [operator, filterColumn, condition, value, setWhere, where, id]);

    useEffect(() => {
        if (table !== '') {
            setFilterColumn('')
            setCondition('')
            setValue('')
            getColumns(table, token).then(response => {
                setColumns(response)
            })
        }
    }, [table])

    useEffect(() => {
        if (count === 0) {
            setCondition('')
            setValue('')
            setFilterColumn('')
        }
    }, [count])


    return (
        <Grid
            container
            style={{marginTop: "1px"}}
            spacing={2}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
        >
            {showLogicalOperator && (
                <Grid item xs={2}>
                    <LogicalOperatorSelect operator={operator} setOperator={setOperator}/>
                </Grid>
            )}
            <Grid item xs={showLogicalOperator ? 3 : 4}>
                <ColumnSelect
                    columns={columns}
                    filterColumn={filterColumn}
                    setFilterColumn={setFilterColumn}
                />
            </Grid>
            <Grid item xs={showLogicalOperator ? 3 : 4}>
                <ConditionSelect condition={condition} setCondition={setCondition}/>
            </Grid>
            <Grid item xs={showLogicalOperator ? 3 : 4}>
                <ValueInput value={value} setValue={setValue}/>
            </Grid>
        </Grid>
    );
}