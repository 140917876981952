import React from "react";
import { Grid } from "@material-ui/core";
import GroupingJoin from "./GroupingJoin";
import AggregationsJoin from "./AggregationsJoin";

export default function AggregationGroupingJoinComponent({
  tables,
  groupingColumns,
  setGroupingColumns,
  joinAggregations,
  setJoinAggregations,
  availableColumns,
}) {
  return (
    <Grid
      spacing={2}
      container
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
    >
      <Grid item xs={12} md={4}>
        <GroupingJoin
          key={JSON.stringify(availableColumns)} // Add a key to force re-render when availableColumns change
          groupColumns={groupingColumns}
          setGroupColumns={setGroupingColumns}
          availableColumns={availableColumns}
          tables={tables}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <AggregationsJoin
          tables={tables}
          joinAggregations={joinAggregations}
          setJoinAggregations={setJoinAggregations}
        />
      </Grid>
    </Grid>
  );
}
