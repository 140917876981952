import React, { useState } from 'react';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1, 0),
  },
  tableContainer: {
    marginTop: theme.spacing(2),
  },
  tableCell: {
    fontWeight: 'bold',
  },
}));

const FilteredTable = ({ tableColumns, selectedVariables }) => {
  const classes = useStyles();
  const [showTable, setShowTable] = useState(true);

  const handleToggleTable = () => {
    setShowTable(!showTable);
  };

  const filteredTables = tableColumns.map(table => {
    const filteredColumns = table.columns.filter(column => selectedVariables.includes(column));
    return filteredColumns.length > 0 ? { ...table, columns: filteredColumns } : null;
  }).filter(table => table !== null);

  return (
    <div className={classes.container}>
      <Button
        variant="contained"
        color="primary"
        onClick={handleToggleTable}
        className={classes.button}
      >
        {showTable ? 'Hide' : 'Show'} Table
      </Button>

      {showTable && (
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCell}>Table Name</TableCell>
                <TableCell className={classes.tableCell}>Columns</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredTables.map((table, index) => (
                <TableRow key={index}>
                  <TableCell>{table.table}</TableCell>
                  <TableCell>{table.columns.join(', ')}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default FilteredTable;
