import React, {useEffect, useState} from 'react';
import {MenuItem, FormControl, InputLabel, Select, Grid, TextField, Paper, Button} from '@material-ui/core';
import {getColumns} from "../utils";


export default function AdvancedFilters({table, where, setWhere, count, id}) {
    const [filterColumn, setFilterColumn] = useState('')
    const [condition, setCondition] = useState('')
    const [value, setValue] = useState('')
    const [row, setRow] = useState(0)
    const [columns, setColumns] = useState([])
    const formStyle = {
        margin: "1px",
        minWidth: "120",
    }

    useEffect(() => {
        if (table !== '') {
            setFilterColumn('')
            setCondition('')
            setValue('')
            getColumns(table).then(response => {
                setColumns(response)
            })
        }
    }, [table])

    useEffect(() => {
        if (count === 0) {
            setCondition('')
            setValue('')
            setFilterColumn('')
        }
    }, [count])

    useEffect(() => {
        let tempWhere = where
        tempWhere[id] = {filterColumn, condition, value}
        setWhere(tempWhere)
    }, [filterColumn, condition, value, setWhere, where, id])

    return (<Paper style={{color: "gray", width: "100%", marginTop: "20px"}}>
        <h1>Advanced filtering</h1>
        <Button>Add 2</Button>
        <Grid container style={{marginTop: "1px"}} spacing={2} direction="row"
              justifyContent="space-between"
              alignItems="center">
            {row>0 && row.map((row,id) =>
            <Grid key={id} item xs={12}>
                <filterExpression/>
            </Grid>)}

            {/*<Grid item xs={4}>*/}
            {/*    <FormControl fullWidth variant="outlined" style={formStyle}>*/}
            {/*        <InputLabel htmlFor="table-label1">Column</InputLabel>*/}
            {/*        <Select*/}
            {/*            style={{width: "150px"}}*/}
            {/*            labelId="table-label1"*/}
            {/*            label="Column"*/}
            {/*            value={filterColumn}*/}
            {/*            onChange={(e) => setFilterColumn(e.target.value)}>*/}
            {/*            {columns.length > 0 && columns.map((column, i) => <MenuItem value={column}*/}
            {/*                                                                        key={i}>{column}</MenuItem>)}*/}
            {/*        </Select>*/}
            {/*    </FormControl>*/}
            {/*</Grid>*/}
            {/*<Grid item xs={4}>*/}
            {/*    <FormControl fullWidth variant="outlined" style={formStyle}>*/}
            {/*        <InputLabel htmlFor="table-label2">Condition</InputLabel>*/}
            {/*        <Select*/}
            {/*            style={{width: "150px"}}*/}
            {/*            labelId="table-label2"*/}
            {/*            label="Condition"*/}
            {/*            value={condition}*/}
            {/*            onChange={(e) => setCondition(e.target.value)}>*/}
            {/*            <MenuItem value="=">Equal to</MenuItem>*/}
            {/*            <MenuItem value=">"> Greater than </MenuItem>*/}
            {/*            <MenuItem value="<">Less than </MenuItem>*/}
            {/*        </Select>*/}
            {/*    </FormControl>*/}

            {/*</Grid>*/}
            {/*<Grid item xs={4}>*/}
            {/*    <FormControl fullWidth variant="outlined" style={formStyle}>*/}
            {/*        <TextField*/}
            {/*            style={{width: "150px"}}*/}
            {/*            variant="outlined"*/}
            {/*            label="Value"*/}
            {/*            value={value}*/}
            {/*            onChange={(e) => setValue(e.target.value)}>*/}
            {/*        </TextField>*/}
            {/*    </FormControl></Grid>*/}
        </Grid>
    </Paper>)
}