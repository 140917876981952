import React, {useEffect, useState} from "react";
import Menu from "./components/Menu";
import Form from "./components/Form";
import {Browser, Button} from '@material-ui/core';
import PropTypes from 'prop-types';

import {makeStyles, useTheme} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import JoinForm from "./components/JoinForm";
import SelectObject from "./components/SelectObject";
import axios from "axios";
import Secured from "./components/Secured";
import {BrowserRouter, useParams, Route, Routes} from "react-router-dom";
import Main from "./components/Main";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component={'span'} variant={'body2'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}


TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `nav-tab-${index}`,
        'aria-controls': `nav-tabpanel-${index}`,
    };
}

function LinkTab(props) {
    return (
        <Tab
            component="a"
            onClick={(event) => {
                event.preventDefault();
            }}
            {...props}
        />
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

function App() {
    const [done, setDone] = useState(false)
    const [select, setSelect] = useState(true)
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [files, setFiles] = useState([])
    const [created, setCreated] = useState([])
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };




    return (
        // <BrowserRouter>
        //     <Routes>
        //         <Route path=":run_id/:step_id" element={<Main created={created} setCreated={setCreated}/>}/>
        //     </Routes>
        // </BrowserRouter>

        <BrowserRouter>
            <Routes>
                <Route path=":run_id/:step_id" element={<Secured created={created} setCreated={setCreated}/>}/>
            </Routes>
        </BrowserRouter>


    );
}

export default App;
