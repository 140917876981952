import React, {useEffect, useState} from 'react';
import {FormControl, Grid, InputLabel, MenuItem,  Select} from '@material-ui/core';
import {getColumns} from "../utils";



const formStyle = {
    margin: "1px",
    minWidth: "120",
}


export default function AggregationJoin({tables, joinAggregations, setJoinAggregations, id}) {
    const [table, setTable] = useState('')
    const [aggregation, setAggregation] = useState('')
    const [column, setColumn] = useState('')
    const [colNames, setColNames] = useState([])

    useEffect(()=>{
        if(table!==''){
            getColumns(table).then(response => setColNames(response))
        }
    },[table])

    useEffect(()=>{
        setColumn('')
        setAggregation('')
        setTable('')
    },[tables])

    useEffect(() => {
        setColumn('')
        setAggregation('')
    },[table, setColumn, setAggregation])

    useEffect(() => {
        let temp = joinAggregations
        temp[id]=[table, column, aggregation]
        setJoinAggregations(temp)
    },[table, aggregation, column, joinAggregations, setJoinAggregations, id])

    return (<Grid spacing={3} container direction="row"
                  justifyContent="space-around"
                  alignItems="flex-start">
        <Grid item xs={4}>
            <FormControl fullWidth variant="outlined" style={formStyle}>
                <InputLabel htmlFor="table-label">Table</InputLabel>
                <Select
                    style={{minWidth: "120px", marginBottom: "10px"}}
                    labelId="table-label"
                    value={table}
                    label="Table"
                    inputProps={{
                        name: 'table',
                        id: 'table-label',
                    }}
                    onChange={(e) => setTable(e.target.value)}>
                    {tables.map((table, i) =>
                        <MenuItem value={table} key={i}>{table}</MenuItem>
                    )}
                </Select>
            </FormControl>
        </Grid>
        <Grid item xs={4}>
            <FormControl fullWidth variant="outlined" style={formStyle}>
                <InputLabel htmlFor="table-label">Column</InputLabel>
                <Select
                    style={{minWidth: "120px", marginBottom: "10px"}}
                    labelId="table-label"
                    value={column}
                    label="Column"
                    inputProps={{
                        name: 'table',
                        id: 'table-label',
                    }}
                    onChange={(e) => setColumn(e.target.value)}>
                    {colNames.map((col, i) =>
                        <MenuItem value={col} key={i}>{col}</MenuItem>
                    )}
                </Select>
            </FormControl>
        </Grid>
        <Grid item xs={4}>
            <FormControl fullWidth variant="outlined" style={formStyle}>
                <InputLabel htmlFor="table-label">Aggregation</InputLabel>
                <Select
                    style={{minWidth: "120px", marginBottom: "10px"}}
                    labelId="table-label"
                    value={aggregation}
                    label="Aggregation"
                    inputProps={{
                        name: 'table',
                        id: 'table-label',
                    }}
                    onChange={(e) => setAggregation(e.target.value)}>
                    <MenuItem value="MAX">MAX</MenuItem>
                    <MenuItem value="MIN">MIN</MenuItem>
                    <MenuItem value="AVG">AVG</MenuItem>
                    <MenuItem value="SUM">SUM</MenuItem>
                    <MenuItem value="COUNT">COUNT</MenuItem>
                </Select>
            </FormControl>
        </Grid>
    </Grid>)
}