import React, {useEffect, useState} from 'react';
import {
    Grid,
    FormControlLabel,
    Checkbox, Button, Paper, TextField
} from '@material-ui/core';
import MultiSelect from "./MultiSelect";
import Filters from "./Filters";
import Alert from "@material-ui/lab/Alert";
import JoinType from "./JoinType";
import {
    checkJoinForm,
    constructJoinQuery,
    joinQuery,
    constructJoinGroupByQuery,
    getTables,
    get_variables,
    executeQuery, save_as_csv, redirect, getColumns, getESDetails, get_sources
} from "../utils";
import ColumnsSelection from "./ColumnsSelection";
import FilterSources from "./FilterSources";
import AggregationGroupingJoinComponent from "./AggregationGroupingJoinComponent";
import TableResult from "./TableResult";
import {useLocation, useParams} from "react-router-dom";
import ProtocolTree from "./ProtocolTree";
import Loader from "./Loader";


export default function JoinForm({
                                     setDone,
                                     created,
                                     setCreated,
                                     bucket,
                                     workflowId,
                                     savePath,
                                     tableNames,
                                     tables,
                                     setErrorTable,
                                     errorTable,
                                     token,
                                     availableVariables,
                                     tableColumns
                                 }) {
    const [joinTables, setJoinTables] = useState([])
    const [joinTypes, setJoinTypes] = useState([])
    const [where, setWhere] = useState([])
    const [aggregations, setAggregations] = useState(false)
    const [isError, setIsError] = useState(false)
    const [selectedColumns, setSelectedColumns] = useState([])
    const message = "Please fill in all the fields according to the rules"
    const [groupingJoinColumns, setGroupingJoinColumns] = useState([])
    const [joinAggregations, setJoinAggregations] = useState([])
    // const [tables, setTables] = useState([])
    const [result, setResult] = useState([])
    const [query, setQuery] = useState("")
    const [whereClause, setWhereClause] = useState("")
    // const [errorTable, setErrorTable] = useState(false)
    const [errorResult, setErrorResult] = useState(false)
    // const [bucket, setBucket] = useState("")
    // const [savePath, setSavePath] = useState("")
    const [availableColumns, setAvailableColumns] = useState({})
    const [fileName, setFileName] = useState('')
    const [errorFileName, setErrorFileName] = useState(false)
    // const [tableNames, setTableNames] = useState([])
    // const [workflowId, setWorkflowId] = useState("")
    const [filterSources, setFilterSources] = useState(false)
    const [filter, setFilter] = useState({})
    const path = useParams()
    const [variables, setVariables] = useState([])
    const [sources, setSources] = useState([])
    const [metadataSelectedVariables, setMetadataSelectedVariables] = useState([])

    const get_available_columns = (tables) => {

        let promises = tables.map((table) => getColumns(table, token));

        Promise.all(promises).then((responses) => {
            const temp = responses.map((response, i) => ({
                columns: response,
                table: tables[i],
            }));
            setAvailableColumns(temp);
        });


    };


    // useEffect(() => {
    //     console.log(path.run_id, path.step_id)
    //     getESDetails(path.run_id, path.step_id)
    //         .then(response => {
    //                 console.log(response)
    //                 setWorkflowId(response.step.metadata.workflow_id)
    //                 setBucket(response.step.metadata.base_save_path.bucket_name)
    //                 setSavePath(response.step.metadata.base_save_path.object_name)
    //                 setTableNames(retrieve_tables(response.step.metadata.data_use.trino))
    //             }
    //         )
    // }, [location]);
    //
    //
    // const retrieve_tables = (objs) => {
    //     let created_tables = created.flat().map(tab => "postgresql.public." + tab)
    //     let table_names = created_tables
    //     if (objs !== null) {
    //         for (let i = 0; i < objs.length; i++) {
    //             table_names.push(`${objs[i].catalog}.${objs[i].schema_}.${objs[i].table}`)
    //             console.log(`${objs[i].catalog}.${objs[i].schema_}.${objs[i].table}`)
    //         }
    //     }
    //
    //     console.log(table_names)
    //     return table_names
    // }
    useEffect(() => {
        setSources(tables)
        console.log(tables)
    }, [tables])

    useEffect(() => {
        console.log("filter=", filter)
        if (typeof filter === 'object' && filter !== null && Object.keys(filter).length === 0) {
            console.log("all")
            setSources(tables)
        } else {
            get_sources(filter, token).then(res => {
                console.log(res)
                setSources(res.sources)
            })
        }
    }, [filter])

    useEffect(() => {
        if (filterSources) {
            get_variables(tables[0].split("___")[0], token).then(res => {
                console.log(res.variables)
                setVariables(res.variables)
            })
        }
    }, [filterSources])

    useEffect(() => {
        console.log(availableColumns)
    }, [availableColumns])

    const format_group_join_columns = (columns, aggregations) => {
        console.log(joinTables, where, aggregations, joinTypes, selectedColumns, groupingJoinColumns, joinAggregations, whereClause)
        let temp = []
        for (let i = 0; i < aggregations.length; i++) {
            temp.push(aggregations[i][1])
        }
        temp.push(columns.flat())
        console.log(temp)
        return temp
    }


    useEffect(() => {
        setResult([])
        setWhereClause("")
        get_available_columns(joinTables)
    }, [joinTables])

    useEffect(() => {
        setWhereClause("")
    }, [where])

    const handleSave = (fileName) => {
        if (fileName) {
            setDone(true);
            save_as_csv(bucket, result, selectedColumns.flat(), savePath + "/" + fileName + ".csv", path.run_id, path.step_id, query, token)
                .then((response) => {
                    console.log("success")
                    setErrorFileName(false)
                    //window.location.replace(`https://es.platform.mes-cobrad.eu/workflow/${workflowId}/run/${path.run_id}`);
                    window.close()
                })
                .catch((error) => console.log(error));
        } else {
            setErrorFileName(true)
            console.log("error")
        }
    };

    const handleSubmit = () => {
        // console.log("selected ", joinTables, where, aggregations, joinTypes, selectedColumns, groupingJoinColumns, joinAggregations)
        // console.log(whereClause)

        setErrorResult(false)
        setResult([])
        //console.log(selectedColumns)
        if (checkJoinForm(joinTables, where, aggregations, joinTypes, selectedColumns, groupingJoinColumns, joinAggregations, whereClause)) {

            setIsError(false)

            if (!aggregations) {
                const data = constructJoinQuery(joinTables, where, aggregations, joinTypes, selectedColumns, whereClause)

                joinQuery(data).then(response => {
                    console.log("response ", response)
                    setQuery(response)
                    executeQuery(response, token).then(response => {
                        console.log(response)
                        if (response === undefined || response.length === 0)
                            setErrorResult(true)
                        else
                            setResult(response)
                    })
                })
            } else {
                const data = constructJoinGroupByQuery(joinTables, where, aggregations, joinTypes, selectedColumns, groupingJoinColumns, joinAggregations, whereClause)
                joinQuery(data).then(response => {
                    console.log(response)
                    setQuery(response)
                    executeQuery(response, token).then(response => {
                        console.log(response)
                        if (response === undefined || response.length === 0)
                            setErrorResult(true)
                        else
                            setResult(response)
                    })
                })
            }
        } else {
            setIsError(true)
        }
    }

    useEffect(() => {
        console.log(metadataSelectedVariables)
    }, [metadataSelectedVariables])

    return (
        <>
            {availableVariables.length > 0 ? <ProtocolTree metadataSelectedVariables={metadataSelectedVariables}
                                                           setMetadataSelectedVariables={setMetadataSelectedVariables}
                                                           tableColumns={tableColumns}
                                                           availableVariables={availableVariables}/> :
                <Loader/>}
            <form style={{marginLeft: "10px", marginRight: "20px"}}>
                <Grid container direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start">
                    <Grid item style={{marginTop: "10px"}} xs={12}>
                        <FormControlLabel
                            control={<Checkbox color="primary"/>}
                            label="Filter sources"
                            onChange={(e) => setFilterSources(!filterSources)}>
                        </FormControlLabel>
                    </Grid>

                    {filterSources && <>
                        <Grid item xs={10}>
                            <FilterSources tables={tables} filter={filter} setFilter={setFilter} options={variables}/>
                        </Grid>

                    </>}
                    <Grid item style={{marginTop: "10px"}} xs={6}>
                        <MultiSelect
                            placeholder="Join Tables" options={sources}
                            setColumns={setJoinTables}/>
                    </Grid>
                    <Grid item xs={5}>
                        <FormControlLabel
                            control={<Checkbox color="primary"/>}
                            label="Aggregations"
                            onChange={(e) => setAggregations(!aggregations)}></FormControlLabel>
                    </Grid>
                    {!aggregations ?
                        <ColumnsSelection tables={joinTables} columns={selectedColumns}
                                          setColumns={setSelectedColumns} availableColumns={availableColumns}/>
                        :
                        <AggregationGroupingJoinComponent joinAggregations={joinAggregations}
                                                          setJoinAggregations={setJoinAggregations}
                                                          setGroupingColumns={setGroupingJoinColumns}
                                                          availableColumns={availableColumns}
                                                          groupingColumns={groupingJoinColumns} tables={joinTables}/>}
                    <Grid item xs={12}>
                        {joinTables.length >= 2 &&
                            <Paper style={{color: "gray", width: "100%", marginTop: "10px"}}>
                                <h3 style={{textAlign: "center"}}>Joins</h3>
                                {[...Array(joinTables.length - 1)].map((e, i) =>
                                    <JoinType key={i} id={i} joinTypes={joinTypes} setJoinTypes={setJoinTypes}
                                              availableColumns={availableColumns} tables={joinTables}/>)}

                            </Paper>}
                    </Grid>
                    <Grid container direction="row"
                          justifyContent="space-between" alignItems="center">
                        <Filters joinTables={joinTables} join={true} where={where} setWhere={setWhere}
                                 whereClause={whereClause}
                                 setWhereClause={setWhereClause} availableColumns={availableColumns}
                                 placeHolder={"users.age>18 AND person.status='unknown' AND NOT event.date>'2022-01-01'"}/>
                    </Grid>


                    <Grid container direction="column"
                          justifyContent="space-evenly"
                          alignItems="center">
                        {isError && <Grid item xs={12}><Alert onClose={() => {
                            setIsError(false)
                        }} severity="error" style={{width: "100%", marginTop: "5px"}}>{message}</Alert></Grid>}
                        <Grid item sx={12}>

                            <Button variant="contained" color="primary" style={{margin: "20px"}}
                                    onClick={handleSubmit}>Submit</Button>
                        </Grid>
                        {errorTable &&
                            <Alert onClose={() => {
                                setErrorTable(false)
                            }} severity="error"
                                   style={{width: "100%", marginTop: "5px"}}>
                                {"Tables are not accessible. Connection error "}
                            </Alert>
                        }

                        {errorResult &&
                            <Alert onClose={() => {
                                setErrorResult(false)
                            }} severity="error"
                                   style={{width: "100%", marginTop: "5px"}}>
                                {"The result is null. Your SQL query may was wrong. Please try again "}
                            </Alert>
                        }

                        {(!errorResult && result.length > 0) &&
                            <>
                                {!aggregations ?
                                    <Grid item sx={12}>
                                        <TableResult columns={selectedColumns.flat()} data={result} query={query}/>
                                    </Grid>
                                    :
                                    <Grid item sx={12}>
                                        <TableResult
                                            columns={format_group_join_columns(groupingJoinColumns, joinAggregations)}
                                            data={result} query={query}/>
                                    </Grid>
                                }
                                {errorFileName &&
                                    <Alert onClose={() => {
                                        setErrorFileName(false)
                                    }} severity="error"
                                           style={{width: "100%", marginTop: "5px"}}>
                                        {"Please provide a file name!"}
                                    </Alert>
                                }


                                <Grid item sx={12}>
                                    <TextField
                                        label="File Name"
                                        variant="outlined"
                                        value={fileName}
                                        onChange={(e) => setFileName(e.target.value)}
                                        style={{marginRight: '10px'}}
                                    />
                                </Grid>
                                <Grid item sx={12}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        style={{margin: '20px'}}
                                        onClick={() => handleSave(fileName)}
                                    >
                                        Save and Exit
                                    </Button>
                                </Grid>
                            </>}
                    </Grid>
                </Grid>
            </form>
        </>)
}