import React, {useEffect, useState} from 'react';
import {
    Select,
    Checkbox,
    FormControlLabel,
    Button,
    Grid,
    MenuItem,
    InputLabel,
    FormControl, Paper, TextField
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert'
import Filters from "./Filters";
import MultiSelect from "./MultiSelect";
import ColumnsList from "./ColumnsList";
import JoinForm from "./JoinForm";
import {
    checkForm,
    selectQuery,
    groupByQuery,
    constructQuery,
    getTables,
    getColumns,
    executeQuery,
    save_as_csv, redirect, getESDetails
} from "../utils";
import TableResult from "./TableResult";
import {useLocation, useParams} from 'react-router-dom';
import AdvancedFilters from "./AdvancedFilters";
import ProtocolTree from "./ProtocolTree";
import Loader from "./Loader"

export default function Form(props) {
    const [table, setTable] = useState('')
    const [aggregations, setAggregations] = useState(false)
    const [columns, setColumns] = useState([])
    const [groupingColumns, setGroupingColumns] = useState([])
    const [where, setWhere] = useState([])
    const [aggregationColumns, setAggregationColumns] = useState([])
    const [isError, setIsError] = useState(false)
//  const [tables, setTables] = useState([])
    const message = "Please fill in all the fields according to the rules"
    const [tableColumns, setTableColumns] = useState([])
    const [result, setResult] = useState([])
    const [query, setQuery] = useState("")
    const [whereClause, setWhereClause] = useState("")
    // const [errorTable, setErrorTable] = useState(false)
    const [errorResult, setErrorResult] = useState(false)
    const location = useLocation()
    // const [bucket, setBucket] = useState("")
    // const [savePath, setSavePath] = useState("")
    const [fileName, setFileName] = useState('')
    const [errorFileName, setErrorFileName] = useState(false)
    // const [tableNames, setTableNames] = useState([])
    // const [workflowId, setWorkflowId] = useState("")
    const [metadataSelectedVariables, setMetadataSelectedVariables] = useState([])


    const path = useParams()
    const formStyle = {
        margin: "1px",
        minWidth: "120",
    }

    const format_group_columns = (aggregations_obj, group_columns) => {
        let temp = []
        for (let i = 0; i < aggregations_obj.length; i++) {
            temp.push(aggregations_obj[i]["aggregation"] + "_" + aggregations_obj[i]["columnName"])
        }
        return temp.concat(group_columns)
    }

    const handleSave = (fileName) => {
        if (fileName) {
            props.setDone(true);
            save_as_csv(props.bucket, result, columns, props.savePath + "/" + fileName + ".csv", path.run_id, path.step_id, query, props.token)
                .then((response) => {
                    console.log("success")
                    setErrorFileName(false)
                    // window.location.replace(`https://es.platform.mes-cobrad.eu/workflow/${props.workflowId}/run/${path.run_id}`);
                    window.close()
                })
                .catch((error) => console.log(error));
        } else {
            setErrorFileName(true)
            console.log("error")
        }

    };

    const handleSubmit = () => {
        //console.log(table, aggregations, columns, where, aggregationColumns, groupingColumns)
        //console.log(whereClause)
        //console.log("where", where)
        setResult([])
        if (checkForm(table, aggregations, columns, where, aggregationColumns, groupingColumns, whereClause)) {
            const data = constructQuery(table, aggregations, columns, where, aggregationColumns, groupingColumns, whereClause)
            setIsError(false)
            if (groupingColumns.length > 0 && aggregations)
                groupByQuery(data).then(response => {
                    console.log(response)
                    setQuery(response)
                    executeQuery(response, props.token).then(response => {
                        console.log("res", response)
                        if (response.length === 0)
                            setErrorResult(true)
                        else
                            setResult(response)
                    })
                        .catch((err) => setErrorResult(true))
                })
            else {
                console.log("select", data)
                selectQuery(data).then(response => {
                    console.log("sel res", response)
                    setQuery(response)
                    executeQuery(response, props.token).then(response => {
                        console.log("res edw", response)
                        if (response.length === 0)
                            setErrorResult(true)
                        else
                            setResult(response)
                    })
                        .catch((err) => setErrorResult(true))
                })
            }

        } else
            setIsError(true)
    }


    useEffect(() => {
        // console.log(result)
        // console.log(aggregationColumns.concat(groupingColumns))
        // console.log(aggregationColumns)
        // console.log(groupingColumns)
    }, [result])


    useEffect(() => {
        setColumns([])
        if (table !== '') {
            getColumns(table, props.token).then(response => {
                setTableColumns(response)
            })
        }
    }, [table])


    useEffect(() => {
        setIsError(false)
        setResult([])
        setWhereClause("")
        setErrorResult(false)
    }, [table, aggregations, where, aggregationColumns, groupingColumns, columns])

    useEffect(() => {
        setAggregations(false)
        setColumns([])
        setGroupingColumns([])
        setWhere([])
        setAggregationColumns([])
        setWhereClause("")
        setErrorResult(false)
    }, [props.select])

    useEffect(() => {
        setGroupingColumns([])
        setAggregationColumns([])
    }, [aggregations])

    useEffect(() => {
        console.log(metadataSelectedVariables)
    }, [metadataSelectedVariables])


    return (
        <>
            {props.availableVariables.length > 0 ? <ProtocolTree metadataSelectedVariables={metadataSelectedVariables}
                                                                 setMetadataSelectedVariables={setMetadataSelectedVariables}
                                                                 tableColumns={props.tableColumns}
                                                                 availableVariables={props.availableVariables}/> :
                <Loader/>}
            <form style={{marginLeft: "10px", marginRight: "20px"}}>
                <Grid container direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start">

                    <Grid style={{marginTop: "10px"}} item xs={6}>
                        <FormControl fullWidth variant="outlined" style={formStyle}>
                            <InputLabel htmlFor="table-label">Table</InputLabel>
                            <Select
                                labelId="table-label"
                                value={table}
                                label="Table"
                                inputProps={{
                                    name: 'table',
                                    id: 'table-label',
                                }}
                                onChange={(e) => setTable(e.target.value)}>
                                {props.tables.map((table, i) =>
                                    <MenuItem value={table} key={i}>{table}</MenuItem>
                                )}
                            </Select>
                        </FormControl>

                    </Grid>


                    <Grid item xs={5}>
                        <FormControlLabel
                            control={<Checkbox color="primary"/>}
                            label="Aggregations"
                            onChange={(e) => setAggregations(!aggregations)}>

                        </FormControlLabel>
                    </Grid>


                    {!aggregations ?
                        <Grid item style={{marginTop: "10px"}} xs={12}> <MultiSelect
                            aggregations={aggregations} placeholder="Columns" options={tableColumns}
                            setColumns={setColumns}/></Grid>
                        : <>

                            <Grid item style={{marginTop: "20px"}} xs={12} md={6}>
                                <MultiSelect
                                    aggregations={aggregations}
                                    placeholder="Grouping Columns" options={tableColumns}
                                    setColumns={setGroupingColumns}/>
                            </Grid>
                            <Grid item xs={12} md={6} style={{paddingLeft: "10px"}}>
                                <Paper style={{color: "gray", width: "100%", marginTop: "20px"}}>
                                    <ColumnsList
                                        options={tableColumns}
                                        aggregationColumns={aggregationColumns}
                                        setAggregationColumns={setAggregationColumns}/>
                                </Paper>
                            </Grid>

                        </>
                    }


                    <Grid container direction="row"
                          justifyContent="space-between" alignItems="center">
                        <Filters token={props.token} table={table} where={where} setWhere={setWhere} whereClause={whereClause}
                                 setWhereClause={setWhereClause}
                                 placeHolder={"age>18 AND status='unknown' AND NOT date>'2022-01-01'"}/>
                    </Grid>


                    <Grid container direction="column"
                          justifyContent="space-evenly"
                          alignItems="center">

                        {isError && <Grid item xs={12}><Alert onClose={() => {
                            setIsError(false)
                        }} severity="error" style={{width: "100%", marginTop: "5px"}}>{message}</Alert></Grid>}
                        <Grid item sx={12}>

                            <Button variant="contained" color="primary" style={{margin: "20px"}}
                                    onClick={handleSubmit}>Submit</Button>
                        </Grid>
                        {props.errorTable &&
                            <Alert onClose={() => {
                                props.setErrorTable(false)
                            }} severity="error"
                                   style={{width: "100%", marginTop: "5px"}}>
                                {"No available or selected tables "}
                            </Alert>
                        }

                        {errorResult &&
                            <Alert onClose={() => {
                                setErrorResult(false)
                            }} severity="error"
                                   style={{width: "100%", marginTop: "5px"}}>
                                {"The result is null. Your SQL query may was wrong. Please try again "}
                            </Alert>
                        }


                        {result.length > 0 &&
                            <>
                                {!aggregations ?
                                    <Grid item sx={12}>
                                        <TableResult columns={columns} data={result} query={query}/>
                                    </Grid>
                                    :
                                    <Grid item sx={12}>
                                        <TableResult columns={format_group_columns(aggregationColumns, groupingColumns)}
                                                     data={result} query={query}/>
                                    </Grid>}

                                {errorFileName &&
                                    <Alert onClose={() => {
                                        setErrorFileName(false)
                                    }} severity="error"
                                           style={{width: "100%", marginTop: "5px"}}>
                                        {"Please provide a file name!"}
                                    </Alert>
                                }


                                <Grid item sx={12}>
                                    <TextField
                                        label="File Name"
                                        variant="outlined"
                                        value={fileName}
                                        onChange={(e) => setFileName(e.target.value)}
                                        style={{marginRight: '10px'}}
                                    />
                                </Grid>
                                <Grid item sx={12}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        style={{margin: '20px'}}
                                        onClick={() => handleSave(fileName)}
                                    >
                                        Save and Exit
                                    </Button>
                                </Grid>
                            </>}

                    </Grid>
                </Grid>

            </form>

        </>
    )

}