import React, {Component} from "react";
import Keycloak, {KeycloakConfig} from "keycloak-js";
import Main from "./Main";




class Secured extends Component {
    constructor(props) {
        super(props);
        this.state = { keycloak: null, authenticated: false };
    }

    componentDidMount() {
        const keycloak = new Keycloak({
            realm: "mescobrad",
            url: "https://idm.digital-enabler.eng.it/auth",
            clientId: "query-builder",
        });

        // const storedToken = localStorage.getItem('es_actual_token');
        // const storedRefreshToken = localStorage.getItem('es_actual_refresh_token');
        const storedToken = null
        const storedRefreshToken = null

        if (storedToken && storedRefreshToken) {
            keycloak.init({ onLoad: 'check-sso', token: storedToken, refreshToken: storedRefreshToken }).then(authenticated => {
                if (authenticated) {
                    this.setState({ keycloak: keycloak, authenticated: authenticated });
                } else {
                    this.initKeycloak(keycloak);
                }
            }).catch(() => {
                this.initKeycloak(keycloak);
            });
        } else {
            this.initKeycloak(keycloak);
        }
    }

    initKeycloak(keycloak) {
        keycloak.init({ onLoad: 'login-required', checkLoginIframe: false }).then(authenticated => {
            this.setState({ keycloak: keycloak, authenticated: authenticated });
            if (authenticated) {
                localStorage.setItem('qr_actual_token', keycloak.token);
                localStorage.setItem('qr_actual_refresh_token', keycloak.refreshToken);
            }
        }).catch(error => {
            console.error("Keycloak initialization failed:", error);
        });
    }

    render() {
        if (this.state.keycloak) {
            if (this.state.authenticated) {
                console.log(this.state.keycloak)
                return <Main created={this.props.created} setCreated={this.props.setCreated} />;
            } else {
                return <div><p>Unable to login</p></div>;
            }
        }
        return <div>Initializing keycloak...</div>;
    }
}

export default Secured;