import React, {useEffect, useState} from 'react';
import {MenuItem, FormControl, InputLabel, Select, Grid, TextField} from '@material-ui/core';
import {getColumns} from "../utils";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function TableResult({columns, data, query}) {
    const classes = useStyles();
    return (<>

        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead style={{backgroundColor: "#e0e0e0"}}>
                    <TableRow>
                        {columns.map((column, i) => <TableCell style={{fontWeight:"bold"}} key={i}>{column}</TableCell>)}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.slice(0,9).map((row, i) => (
                        <TableRow key={i}>
                            {row.map((element, j) => <TableCell align="left" key={j}>{element}</TableCell>)}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        <h4>{data.length} row(s)</h4>
        <i>Executed SQL Query: </i><h5>{query}</h5>
    </>)
}