import React, {useEffect, useState} from 'react';
import {MenuItem, FormControl, InputLabel, Select, Grid, Paper, Container} from '@material-ui/core';
import {getColumns} from "../utils";


export default function JoinType({tables, id, joinTypes, setJoinTypes, availableColumns}) {
    const [joinTable1, setJoinTable1] = useState('')
    const [joinColumn1, setJoinColumn1] = useState('')
    const [joinTable2, setJoinTable2] = useState('')
    const [joinColumn2, setJoinColumn2] = useState('')
    const [columns1, setColumns1] = useState(['col1', 'col2'])
    const [columns2, setColumns2] = useState([])


    useEffect(() => {
        setJoinTable1('')
        setJoinColumn1('')
        setJoinTable2('')
        setJoinColumn2('')

    }, [tables])

    useEffect(() => {
        //getColumns(joinTable1).then(response => setColumns1(response))
        if (availableColumns!==undefined){
            for(let i=0;i<availableColumns.length;i++){
                if(joinTable1===availableColumns[i].table){
                    setColumns1(availableColumns[i].columns)
                    break;
                }
            }
        }
    }, [joinTable1])

    useEffect(() => {
        //getColumns(joinTable2).then(response => setColumns2(response))
        if (availableColumns!==undefined){
            console.log(availableColumns, joinTable2)
            for(let i=0;i<availableColumns.length;i++){
                if(joinTable2===availableColumns[i].table){
                    setColumns2(availableColumns[i].columns)
                    break;
                }
            }
        }
    }, [joinTable2])


    useEffect(() => {
        const temp = joinTypes
        temp[id] = {joinTable1, joinTable2, joinColumn1, joinColumn2}
        setJoinTypes(temp)
    }, [id, joinTypes, setJoinTypes, joinTable1, joinTable2, joinColumn1, joinColumn2, setJoinTable1, setJoinTable2, setJoinColumn1, setJoinColumn1, setJoinColumn2,])

    const formStyle = {
        margin: "1px",
        minWidth: "120",
    }

    return (
        <Container maxWidth={"xl"}>
            <Paper style={{width: "100%", marginTop: "20px"}} elevation={3}>
                <Grid spacing={2} container direction="row"
                      justifyContent="space-between"
                      alignItems="center">
                    <Grid item xs={6}>
                        <FormControl fullWidth variant="outlined" style={formStyle}>
                            <InputLabel htmlFor="table-label1">Table 1</InputLabel>
                            <Select
                                labelId="table-label1"
                                label="Table 1"
                                value={joinTable1}
                                onChange={(e) => setJoinTable1(e.target.value)}>
                                {tables.map((table) => (
                                    <MenuItem key={table} value={table}>{table}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                        {(columns1 !== undefined && joinTable1!=="") && <FormControl fullWidth variant="outlined" style={formStyle}>
                            <InputLabel htmlFor="table-label">Join Column 1</InputLabel>
                            <Select
                                labelId="table-label"
                                value={joinColumn1}
                                label="Join Column 1"
                                inputProps={{
                                    name: 'table',
                                    id: 'table-label',
                                }}
                                onChange={(e) => setJoinColumn1(e.target.value)}>

                                {columns1.map((column) => (<MenuItem key={column} value={column}>{column}</MenuItem>))}
                            </Select>
                        </FormControl>}
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth variant="outlined" style={formStyle}>
                            <InputLabel htmlFor="table-label1">Table 2</InputLabel>
                            <Select
                                labelId="table-label1"
                                label="Table 2"
                                value={joinTable2}
                                onChange={(e) => setJoinTable2(e.target.value)}>
                                {tables.map((table) => (
                                    table !== joinTable1 &&
                                    <MenuItem key={table} value={table}>{table}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                        {(columns2 !== undefined && joinTable2 !=="") && <FormControl fullWidth variant="outlined" style={formStyle}>
                            <InputLabel htmlFor="table-label">Join Column 2</InputLabel>
                            <Select
                                labelId="table-label"
                                value={joinColumn2}
                                label="Join Column 2"
                                inputProps={{
                                    name: 'table',
                                    id: 'table-label',
                                }}
                                onChange={(e) => setJoinColumn2(e.target.value)}>
                                {columns2.length > 0 && columns2.map((column) => (
                                    <MenuItem key={column} value={column}>{column}</MenuItem>))}
                            </Select>
                        </FormControl>}
                    </Grid>
                </Grid>
            </Paper></Container>)
}