import React from 'react';
import Choice from "./Choice";

export default function Menu(props) {
    const styleButton = {
        width: "100%",
        align: "center"
    }
    return(<div>
        <Choice style={styleButton} name={"Select"} select={props.select} setSelect={props.setSelect}/>
        <br/>
        <Choice style={styleButton} name={"Join"} select={props.select} setSelect={props.setSelect}/>
    </div>)
}