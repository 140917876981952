import React from 'react';
import { Button } from '@material-ui/core';


export default class Choice extends React.Component{
    handleClick(){
        if (this.props.name==="Select"){
            this.props.setSelect(true)
        }
        else{
            this.props.setSelect(false)
        }
    }

    render() {
        return (
            <Button style={{width:"100%"}} onClick={()=>this.handleClick()}>
                {this.props.name}</Button>
        )
    }
}
