/* eslint-disable no-use-before-define */
import React, {useEffect, useState} from 'react';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {makeStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
    root: {
        width: 500,
        '& > * + *': {
            marginTop: theme.spacing(3),
        },
    },
}));


export default function MultiSelect({setColumns, placeholder, options}) {
    const classes = useStyles();
    const [selected, setSelected] = useState([]);

    useEffect(() => {
        setColumns([])
        setSelected([])
    }, [options, setColumns])

    return (
        <div className={classes.root} style={{ width: "100%"}}>

            <Autocomplete
                multiple
                id="tags-outlined"
                options={options}
                getOptionLabel={(option) => option}
                defaultValue={[]}
                disableCloseOnSelect={true}
                filterSelectedOptions
                value={selected}

                onChange={
                    (event, value) => {
                        setColumns(value)
                        setSelected(value)
                        console.log(value)
                    }
                }
                renderInput={(params) => (

                    <TextField

                        {...params}
                        variant="outlined"
                        label={placeholder}
                    />
                )
                }
            />

        </div>
    );
}

