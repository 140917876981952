import React from 'react';
import {Grid, Paper} from '@material-ui/core';
import ColumnSelection from "./ColumnSelection";



export default function ColumnsSelection({setColumns, columns, tables, availableColumns}) {

    return (
        <Paper style={{color: "gray", width: "100%", marginTop: "20px"}}>

            <Grid container direction="column"
                  justifyContent="space-between"
                  alignItems="center">
                <h3>Select Columns</h3>
                {tables && availableColumns.length===tables.length && tables.map((table, i) =>
                    (<Grid key={i} style={{width: "100%"}} item xs={12}>
                        <ColumnSelection id={i} table={table}  columns={columns}
                                         setColumns={setColumns} availableColumns={availableColumns}/>
                    </Grid>))}
            </Grid>
        </Paper>)
}