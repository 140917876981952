import React, {useEffect, useState} from 'react';
import {Button, Grid} from '@material-ui/core';
import AggregationColumn from "./AggregationColumn";

export default function ColumnsList({options, aggregationColumns, setAggregationColumns}) {
    const [columnsCount, setColumnsCount] = useState(1)

    const handleAddColumn = () => {
        let tempCount = columnsCount;
        setColumnsCount(tempCount+1)
    }
    const handleClearColumns = () => {
        setColumnsCount(1)
        setAggregationColumns([])
    }

    useEffect(() => {
        setColumnsCount(1)
        setAggregationColumns([])
    }, [options, setColumnsCount, setAggregationColumns])

    return (<Grid container direction="column"
                  justifyContent="space-between"
                  alignItems="center">
        {[...Array(columnsCount)].map((e, i) =>
            <Grid key={i} item sx={10}>
                <AggregationColumn key={i} id={i} options={options} aggregationColumns={aggregationColumns} setAggregationColumns={setAggregationColumns}/>
            </Grid>
        )}


        <Grid item sx={2}>
            <Button onClick={handleAddColumn} variant="contained" color="primary"  style={{margin: "30px"}}>+Add
                Column</Button>
            <Button onClick={handleClearColumns} variant="contained" color="secondary"  style={{margin: "30px"}}>Clear Columns</Button>
        </Grid>

    </Grid>)
}