import React, {useEffect, useState} from 'react';
import {Grid} from '@material-ui/core';
import MultiSelect from "./MultiSelect";
import {getColumns} from "../utils";

export default function ColumnSelection({table, id, setColumns, columns, availableColumns}) {
    const [tableColumns, setTableColumns] = useState([])
    const [columnNames, setColumnNames] = useState([])

    // useEffect(() => {
    //     console.log(availableColumns, id)
    //     if(table !== ''){
    //         getColumns(table).then(response => setColumnNames(response))
    //     }
    // }, [table])

    useEffect(() => {
        console.log(availableColumns, id, availableColumns[id].columns)
        setColumnNames(availableColumns[id].columns)
    }, [availableColumns])

    useEffect(() => {
        setTableColumns([])
    }, [table, setTableColumns])

    useEffect(() => {
        const temp = columns
        temp[id] = tableColumns
        setColumns(temp)
    }, [tableColumns, columns, id, setColumns])

    return (
        <Grid container style={{marginTop: "5px"}} spacing={2} direction="row"
              justifyContent="space-between"
              alignItems="center">
            <Grid style={{width: "100%"}} item xs={12}>
                {columnNames.length>0 && <MultiSelect options={columnNames} setColumns={setTableColumns} placeholder={table}/>}
            </Grid>

        </Grid>)

}