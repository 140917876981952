import React, {useEffect, useState} from 'react';
import {Button, Grid, Paper} from '@material-ui/core';
import AggregationJoin from "./AggregationJoin";


export default function AggregationsJoin({tables, joinAggregations, setJoinAggregations}) {
    const [count, setCount] = useState(1)

    const handleAddAggregation = () => {
        setCount(count + 1)
    }

    useEffect(() => {
        setCount(1)
        setJoinAggregations([])
    }, [tables, setJoinAggregations])

    const handleClearAggregations = () => {
        setCount(1)
        setJoinAggregations([])
    }

    useEffect(() => {
        setCount(1)
    }, [tables])


    return (<Paper style={{color: "gray", width: "100%", height: "100%", marginTop: "20px"}}>
        <Grid container direction="column"
              justifyContent="space-between"
              alignItems="center">
            <h3>Aggregation Columns</h3>
            {tables.length > 0 &&
            <>
                {[...Array(count)].map((e, iter) =>
                    <Grid item key={iter}>
                        <AggregationJoin id={iter} joinAggregations={joinAggregations}
                                         setJoinAggregations={setJoinAggregations} tables={tables}/>
                    </Grid>
                )}


                <Grid item>
                    <Button onClick={handleAddAggregation} variant="contained" color="primary"
                            style={{margin: "30px"}}>+Add Aggregation</Button>
                    <Button onClick={handleClearAggregations} variant="contained" color="secondary"
                            style={{margin: "30px"}}>Clear Aggregations</Button>
                </Grid>
            </>}

        </Grid>
    </Paper>)
}