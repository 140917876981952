import {makeStyles} from "@material-ui/core/styles";
import axios from "axios";
import {replaceBehavior} from "@testing-library/user-event/dist/keyboard/plugins";
//
//let qb_path = 'http://10.129.150.120:8000/';
// let qb_path = 'https://10.129.150.120:471/';
// let an_path = 'http://10.129.150.120:7000/';
// let es_path = 'https://esbk.platform.mes-cobrad.eu'
// let qb_path='http://127.0.0.1:8000/'
// // let es_path= 'https://esbk.platform.mes-cobrad.eu/run/'
// let es_path = 'http://127.0.0.1:8000/'
// let an_path = 'http://127.0.0.1:7000/';
var qb_path, es_path

let dev = "prod"


if (dev === "dev") {
    qb_path = 'http://127.0.0.1:8000/'
    es_path = 'http://127.0.0.1:8000/'
} else if (dev === "prod") {
    es_path = 'https://esbk.platform.mes-cobrad.eu/run/'
    qb_path = 'https://qbbk.platform.mes-cobrad.eu/';

} else if (dev === "test") {
    qb_path = 'http://127.0.0.1:8000/'
    es_path = 'https://esbk.platform.mes-cobrad.eu/run/'
}


const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        width: 300
    },
    indeterminateColor: {
        color: "#f50057"
    },
    selectAllText: {
        fontWeight: 500
    },
    selectedAll: {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
        "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.08)"
        }
    }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    },
    getContentAnchorEl: null,
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "center"
    },
    transformOrigin: {
        vertical: "top",
        horizontal: "center"
    },
    variant: "menu"
};

const options = [
    "column1", "column2", "column3", "column4"
];
const constructJoinQuery = (joinTables, where, aggregations, joinTypes, selectedColumns, whereClause) => {
    console.log(whereClause)
    const data = {
        "join_tables": joinTables,
        "columns_table_list": selectedColumns
    }
    const temp_join = []
    for (let i = 0; i < joinTypes.length; i++) {
        temp_join[i] = {
            "table1": joinTypes[i].joinTable1,
            "column1": joinTypes[i].joinColumn1,
            "table2": joinTypes[i].joinTable2,
            "column2": joinTypes[i].joinColumn2
        }
    }
    data.join_types = temp_join
    return constructWhereJoinClause(data, where, whereClause)
}

const buildJoinWhereClause = (where) => {
    let temp = "where "
    for (let i = 0; i < where.length; i++) {
        if (isNaN(where[i]["value"])) {
            temp += where[i]["operator"] + " [" + where[i]["filterTable"] + "].[" + where[i]["filterColumn"] + "]" + where[i]["condition"] + "'" + where[i]["value"] + "' "
        } else {
            temp += where[i]["operator"] + " [" + where[i]["filterTable"] + "].[" + where[i]["filterColumn"] + "]" + where[i]["condition"] + where[i]["value"] + " "
        }
    }
    return temp
}

const constructWhereJoinClause = (data, where, whereClause) => {
    console.log("hgggg", data, "where", where, "where_clause", whereClause)
    if (whereClause !== "" && whereClause !== undefined) data.where_fixed_clause = "WHERE " + whereClause
    else if (where.length === 0) return data
    else {
        data.where_fixed_clause = buildJoinWhereClause(where)
    }
    return data
}

const checkJoinForm = (joinTables, where, aggregations, joinTypes, selectedColumns, groupingJoinColumns, joinAggregations, whereClause) => {
    if (joinTables.length < 2) return false
    else {
        //check join types
        for (let i = 0; i < joinTypes.length; i++) {
            if (joinTypes[i].joinTable1 === '' || joinTypes[i].joinTable2 === ''
                || joinTypes[i].joinColumn1 === '' || joinTypes[i].joinColumn2 === '') {
                return false
            }
        }

        if (aggregations === true) {
            //check aggregations
            if (joinAggregations.length === 0) return false
            for (let i = 0; i < joinAggregations.length; i++) {
                if (joinAggregations[i][0] === '' || joinAggregations[i][1] === '' || joinAggregations[i][2] === '') {
                    return false
                }
            }
            //check grouping columns
            for (let i = 0; i < joinTables.length; i++) {
                for (let j = 0; j < groupingJoinColumns[i].length; j++) {
                    for (let k = 0; k < joinAggregations.length; k++) {
                        if (joinTables[i] === joinAggregations[k][0] && groupingJoinColumns[i][j] === joinAggregations[k][1])
                            return false
                    }
                }
            }

        } else {
            //check selected columns
            let flag = false
            for (let i = 0; i < selectedColumns.length; i++) {
                if (selectedColumns[i].length > 0) {
                    flag = true
                }
            }
            if (!flag) return false
        }
    }
    //check filters
    if (where.length === 1 && where[0].condition === '' && where[0].filterColumn === ''
        && where[0].filterTable === '' && where[0].value === '') return true
    for (let i = 0; i < where.length; i++) {
        if (where[i].condition === '' || where[i].filterColumn === ''
            || where[i].filterTable === '' || where[i].value === '') {
            return false
        }
    }
    return true
}


const checkForm = (table, aggregations, columns, where, aggregationColumns, groupingColumns, whereClause) => {
    //console.log("table",table, "aggregations",aggregations,"columns", columns, "where", where,"aggregationColumns", aggregationColumns,"groupingColumns", groupingColumns)
    if (table === '') return false;
    if (aggregations) {
        if (aggregationColumns.length === 0) return false;
        else {
            if (groupingColumns.length > 0) {
                for (let i = 0; i < aggregationColumns.length; i++) {
                    if (groupingColumns.includes(aggregationColumns[i].columnName)) return false;
                }
            }
        }
        for (let i = 0; i < aggregationColumns.length; i++) {
            if (aggregationColumns[i].columnName === '' || aggregationColumns[i].aggregation === '') return false;
        }
    } else {
        if (columns.length === 0) return false;
    }
    if (where.length === 1 && where[0].filterColumn === '' && where[0].condition === '' && where[0].value === '') return true;
    if (where.length > 0) {
        for (let i = 0; i < where.length; i++) {
            if (where[i].filterColumn === '' || where[i].condition === '' || where[i].value === '') return false;
        }
    }
    return true
}

const selectQuery = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(qb_path + 'complex/select/query', data)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

const joinQuery = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(qb_path + 'complex/join/query', data)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

const groupByQuery = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(qb_path + 'complex/group/query', data)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

const buildWhereClause = (where) => {
    let temp = "where "
    console.log("sss", where)
    for (let i = 0; i < where.length; i++) {
        if (isNaN(where[i]["value"])) {
            temp += where[i]["operator"] + " [" + where[i]["filterColumn"] + "]" + where[i]["condition"] + "'" + where[i]["value"] + "' "
        } else {
            temp += where[i]["operator"] + " [" + where[i]["filterColumn"] + "]" + where[i]["condition"] + where[i]["value"] + " "
        }
    }
    return temp
}

const constructWhereClause = (data, where, whereClause) => {
    buildWhereClause(where)
    if (whereClause !== "") data.where_fixed_clause = "WHERE " + whereClause
    else if (where.length === 0) return data
    else data.where_fixed_clause = buildWhereClause(where)
    return data
}

const constructQuery = (table, aggregations, columns, where, aggregationColumns, groupingColumns, whereClause) => {
    const data = {
        "table": table
    }
    if (groupingColumns.length > 0) {
        data.grouping_columns = groupingColumns
        let aggregationsList = []
        let columnsList = []
        for (let i = 0; i < aggregationColumns.length; i++) {
            columnsList.push(aggregationColumns[i].columnName)
            aggregationsList.push(aggregationColumns[i].aggregation)
        }
        data.aggregation_metrics = aggregationsList
        data.aggregation_columns = columnsList
    } else {
        if (aggregations) {
            let aggregations = []
            let columnsList = []
            for (let i = 0; i < aggregationColumns.length; i++) {
                columnsList.push(aggregationColumns[i].columnName)
                aggregations.push(aggregationColumns[i].aggregation)
            }
            data.aggregation_metrics = aggregations
            data.aggregation_columns = columnsList
        } else {
            data.columns = columns
        }
    }
    let result = constructWhereClause(data, where, whereClause)
    console.log("querrryryryyr", data)
    return result
}

const constructJoinGroupByQuery = (joinTables, where, aggregations, joinTypes, selectedColumns, groupingJoinColumns, joinAggregations, whereClause) => {
    console.log("inside", whereClause)
    const data = {
        "join_tables": joinTables
    }
    const temp_join = []
    for (let i = 0; i < joinTypes.length; i++) {
        temp_join[i] = {
            "table1": joinTypes[i].joinTable1,
            "column1": joinTypes[i].joinColumn1,
            "table2": joinTypes[i].joinTable2,
            "column2": joinTypes[i].joinColumn2
        }
    }
    data.join_types = temp_join
    let group = []
    for (let i = 0; i < joinTables.length; i++) {
        for (let j = 0; j < groupingJoinColumns[i].length; j++) {
            group.push({"table": joinTables[i], "column": groupingJoinColumns[i][j]})
        }
    }
    data.grouping_columns = group
    let temp_columns = []
    let temp_aggregations = []
    for (let i = 0; i < joinTables.length; i++) {
        temp_columns[i] = []
        temp_aggregations[i] = []
    }
    for (let i = 0; i < joinAggregations.length; i++) {
        for (let j = 0; j < joinTables.length; j++) {
            if (joinAggregations[i][0] === joinTables[j]) {
                temp_columns[j].push(joinAggregations[i][1])
                temp_aggregations[j].push(joinAggregations[i][2])
            }
        }
    }
    data.aggregation_metrics_table_list = temp_aggregations
    data.columns_table_list = temp_columns

    return constructWhereJoinClause(data, where, whereClause)
}

const getTables = (names, token) => {
    return new Promise((resolve, reject) => {
        axios.post(qb_path + `tables/${token}`, names)
            .then(response => {
                if (response.data.tables.length === 0) reject("something went wrong")
                resolve(response.data.tables)
            })
            .catch(error => reject(error))
    })
}

const getESDetails = (run_id, step_id) => {
    return new Promise((resolve, reject) => {
        axios.get(es_path + `${run_id}/step/${step_id}/ping`,
            {
                headers: {
                    'x-es-token': localStorage.getItem('token'),
                    'x-jwt-token': localStorage.getItem('es_actual_token')
                }
            })
            .then(response => {
                if (response.data.length === 0) reject("something went wrong")
                resolve(response.data)
            })
            .catch(error => reject(error))
    })
}
const getColumns = (table, token) => {
    return new Promise((resolve, reject) => {
        axios.post(qb_path + 'tables/', {
            table: table,
            token: token
        })
        .then(response => {
            resolve(response.data.columns);
            console.log(response.data.columns);
        })
        .catch(error => {
            reject(error);
        });
    });
};


const getColumnsOfMultTables = (tables) => {
    return new Promise((resolve, reject) => {
        axios.post(qb_path + `tables/columns`, tables)
            .then(response => {
                resolve(response.data.columns)
            })
            .catch(error => reject(error))
    })
}

const executeQuery = (query, token) => {
    //console.log(query)
    return new Promise((resolve, reject) => {
        axios.post(qb_path + `execute_query/${token}`, {"query": query})
            .then(response => {
                resolve(response.data.result)
            })
            .catch(error => reject(error))
    })
}

const get_buckets = (token) => {
    return new Promise((resolve, reject) => {
        axios.get(qb_path + `buckets/${token}`)
            .then(response => resolve(response.data.buckets))
            .catch(error => reject(error))
    })
}

const get_objects_from_bucket = (bucket, token) => {
    return new Promise((resolve, reject) => {
        axios.get(qb_path + 'buckets/' + bucket + `/${token}`)
            .then(response => resolve(response.data.objects))
            .catch(error => reject(error))
    })
}
const convert_to_table = (bucket, objects) => {
    return new Promise(((resolve, reject) => {
        axios.post(qb_path + `objects/table/save`, objects, {
            params: {'bucket': bucket}, headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                console.log(response)
                resolve(true)
            })
            .catch(error => reject(error))
    }))
}


const convert_tables = (selected, names, buckets, token) => {
    return new Promise(((resolve, reject) => {
        axios.post(qb_path + `objects/table/save/${token}`, {selected, names, buckets}, {
            params: {
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }
        })
            .then(response => {
                console.log(response)
                resolve(response.data.created)
            })
            .catch(error => reject(error))
    }))
}

const save_as_csv = (bucket, result, columns, filename, run_id, step_id, query, token) => {
    return new Promise((resolve, reject) => {
        console.log(filename, query)
        axios.post(qb_path + `buckets/${bucket}/save/${step_id}/${run_id}/${token}`,
            {
                "result": result,
                "columns": columns,
                "filename": filename,
                "name": Date(Date.now()).slice(0, 25).replaceAll(":", "").replaceAll("+", "").replaceAll(" ", "") + ".csv",
                "query": query
            })
            .then(response => {
                console.log(response)
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
                console.log(error)
            })
    })
}

const get_variables = (table, token) => {
    return new Promise((resolve, reject) => {
        axios.post(qb_path + `variables/${token}`,
            {
                "table": table
            })
            .then(response => {
                console.log(response)
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
                console.log(error)
            })

    })
}
const get_available_variables = (tables, token) => {
    return new Promise((resolve, reject) => {
        axios.post(qb_path + `tables/unique_columns/${token}`, {
            "tables_list": tables
        })
            .then(response => {
                console.log(response)
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
                console.log(error)
            })
    })
}
const get_sources = (filter, token) => {
    return new Promise((resolve, reject) => {
        axios.post(qb_path + `variables/tables/${token}`,
            {
                filter
            })
            .then(response => {
                console.log(response)
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
                console.log(error)
            })

    })
}

const redirect = (files, bucket) => {
    // Send the request

    axios.put(an_path + "function/navigation/",
        {
            run_id: "1",
            step_id: "1",
            metadata: {
                "function": "normality",
                "files": files.map(x => bucket + "/" + x)
            },
        }
    ).then(res => {
        console.log("BACK_________________________")
        console.log("BACK")
        console.log(res.data.url)
        window.location.replace(res.data.url)
        // navigate(res.data.url);
    }).catch(error => console.log(error));
}

const transformData = (data) => {
    // Parse the input data if it's in JSON string format
    const parsedData = typeof data === 'string' ? JSON.parse(data) : data;

    // Initialize an empty object to hold the transformed data
    const result = {};

    // Iterate through each item in the parsed data array
    parsedData.forEach(item => {
        // Create the 'name' field by concatenating 'catalog', 'schema_', and 'table'
        const nameField = `${item.catalog}.${item.schema_}.${item.table}`;

        // Check if this 'name' field already exists in the result object
        if (!result[nameField]) {
            // If not, initialize it with an empty 'tables' array
            result[nameField] = {
                name: nameField,
                tables: []
            };
        }

        // Push the 'name' field of the current item into the 'tables' array
        result[nameField].tables.push(item.name);
    });

    // Convert the result object into an array
    return Object.values(result);
};

export {
    useStyles,
    MenuProps,
    options,
    checkForm,
    groupByQuery,
    selectQuery,
    constructQuery,
    checkJoinForm,
    constructJoinQuery,
    joinQuery,
    constructJoinGroupByQuery,
    getTables,
    getColumns,
    executeQuery,
    get_buckets,
    get_objects_from_bucket,
    save_as_csv,
    convert_to_table,
    getColumnsOfMultTables,
    redirect,
    convert_tables,
    getESDetails,
    get_variables,
    get_sources,
    transformData,
    get_available_variables
};
