import React, {useEffect, useState} from "react";
import Form from "./Form";
import PropTypes from 'prop-types';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import JoinForm from "./JoinForm";
import SelectObject from "./SelectObject";
import {BrowserRouter, Route, Routes, useParams, useLocation} from "react-router-dom";
import {getESDetails, getTables, transformData, get_available_variables} from "../utils";
import Loader from "./Loader";
import ProtocolTree from "./ProtocolTree";


function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component={'span'} variant={'body2'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}


TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `nav-tab-${index}`,
        'aria-controls': `nav-tabpanel-${index}`,
    };
}

function LinkTab(props) {
    return (
        <Tab
            component="a"
            onClick={(event) => {
                event.preventDefault();
            }}
            {...props}
        />
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

function Main({created, setCreated}) {
    const [done, setDone] = useState(false)
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const [workflowId, setWorkflowId] = useState("")
    const [bucket, setBucket] = useState("")
    const [savePath, setSavePath] = useState("")
    const [tableNames, setTableNames] = useState([])
    const [tables, setTables] = useState([])
    const [errorTable, setErrorTable] = useState(false)
    const [data, setData] = useState([])
    const [loaded, setLoaded] = useState(true)
    const [availableVariables, setAvailableVariables] = useState([])
    const [tableColumns, setTableColumns] = useState([])
    const {run_id, step_id} = useParams()
    const location = useLocation()
    const [token, setToken] = useState(null)

    useEffect(() => {
        const token = localStorage.getItem('qr_actual_token');
        if (token) {
            setToken(token);
        }
    }, []);

    useEffect(() => {
        console.log(tables)
        get_available_variables(tables, token).then(response => {
            console.log(response)
            console.log(response.table_columns)
            setAvailableVariables(response.unique_columns)
            setTableColumns(response.table_columns)
        })
    }, [tables]);


    // Defined the tabs and their conditions outside
    const tabs = [
        {
            label: "Select Queries",
            href: "/select",
            condition: tables.length > 0 || created.length > 0,
            component: (
                <Form
                    created={created}
                    setCreated={setCreated}
                    workflowId={workflowId}
                    bucket={bucket}
                    savePath={savePath}
                    tableNames={tableNames}
                    tables={tables}
                    setErrorTable={setErrorTable}
                    errorTable={errorTable}
                    setDone={setDone}
                    token={token}
                    availableVariables={availableVariables}
                    tableColumns={tableColumns}
                />
            ),
        },
        {
            label: "Join Queries",
            href: "/join",
            condition: tables.length > 0 || created.length > 0,
            component: (
                <JoinForm
                    created={created}
                    setCreated={setCreated}
                    workflowId={workflowId}
                    bucket={bucket}
                    savePath={savePath}
                    tableNames={tableNames}
                    tables={tables}
                    setErrorTable={setErrorTable}
                    errorTable={errorTable}
                    setDone={setDone}
                    token={token}
                    availableVariables={availableVariables}
                    tableColumns={tableColumns}
                />
            ),
        },
        {
            label: "Convert Object(s)",
            href: "/objects",
            condition: data !== null && data.length > 0,
            component: (
                <SelectObject
                    data={data}
                    created={created}
                    setCreated={setCreated}
                    setDone={setDone}
                    token={token}
                />
            ),
        }
    ];


    // Filter the available tabs based on the condition
    const availableTabs = tabs.filter((tab) => tab.condition);


    useEffect(() => {
        if (value >= availableTabs.length) {
            // If the current value is greater than the length of the available tabs, reset to 0
            setValue(0);
        }
    }, [availableTabs]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const path = useParams()
    useEffect(() => {
        setLoaded(false)
        console.log(path.run_id, path.step_id)
        getESDetails(path.run_id, path.step_id)
            .then(response => {
                    console.log(response)
                    setWorkflowId(response.step.metadata.workflow_id)
                    setBucket(response.step.metadata.base_save_path.bucket_name)
                    setSavePath(response.step.metadata.base_save_path.object_name)
                    setTableNames(transformData(response.step.metadata.data_use.trino))
                    setData(response.step.metadata.data_use.datalake)
                    setLoaded(true)
                }
            )
    }, [location]);

    // const retrieve_tables = (objs) => {
    //     console.log(objs)
    //     let temp_name;
    //     //let created_tables = created.flat().map(tab => "postgresql.public." + tab)
    //     let table_names = []
    //     if (objs !== null) {
    //         for (let i = 0; i < objs.length; i++) {
    //
    //             if (objs[i].name!=="") {
    //                 temp_name = `${objs[i].catalog}.${objs[i].schema_}.${objs[i].table}.${objs[i].name}`
    //             }
    //             else {
    //                 temp_name = `${objs[i].catalog}.${objs[i].schema_}.${objs[i].table}`
    //             }
    //             table_names.push(temp_name)
    //             console.log(temp_name)
    //         }
    //     }
    //
    //     return table_names
    // }
    useEffect(() => {
        let temp = [...tableNames];
        let created_tables = created.flat().map(tab => "postgresql.public." + tab);

        created_tables.forEach((table) => {
            if (!temp.includes(table)) {
                temp.push(table);
            }
        });

        setTableNames(temp);
    }, [created]);

    useEffect(() => {
        console.log(token)
        if (token !== null) {
            //print(tableNames, token)
            getTables(tableNames, token)
                .then(response => {
                    setErrorTable(false)
                    setTables(response)
                })
                .catch((er) => {
                    console.log("cannot reach tables")
                    setErrorTable(true)
                })
        }
    }, [tableNames])

    return (
        <div className={classes.root}>
            {loaded && <AppBar position="static">
                <Tabs
                    variant="fullWidth"
                    value={value}
                    onChange={handleChange}
                    aria-label="nav tabs example"
                >
                    {availableTabs.map((tab, index) => (
                        <LinkTab label={tab.label} href={tab.href} {...a11yProps(index)} key={index}/>
                    ))}
                </Tabs>
            </AppBar>}
            {!loaded && <Loader/>}

            {done === false ? (
                <>
                    {availableTabs.map((tab, index) => (
                        <TabPanel value={value} index={index} key={index}>
                            {tab.component}
                        </TabPanel>
                    ))}
                </>
            ) : (
                <>
                    <h3>Proceed to the next step</h3>
                </>
            )}
        </div>
    );
}

export default Main;
