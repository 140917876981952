import React, {useEffect, useState} from "react";
import {Checkbox, InputLabel, ListItemIcon, ListItemText, MenuItem, FormControl, Select} from "@material-ui/core";
import {MenuProps, useStyles} from "../utils";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {makeStyles} from "@material-ui/core/styles";

const useStyles1 = makeStyles((theme) => ({
    root: {
        width: 500,
        '& > * + *': {
            marginTop: theme.spacing(3),
        },
    },
}));

function MultiSelectJoin({setColumns,  placeholder, options, id, groupColumns, setGroupColumns, length }) {
    const classes = useStyles1();
    const [selected, setSelected] = useState([]);


    useEffect(() => {
        setGroupColumns([])
        setSelected([])
    }, [length, setSelected, setGroupColumns])

    useEffect(() => {
        let temp = groupColumns
        temp[id] = selected
        setGroupColumns(temp)
    }, [selected, setColumns, id, setGroupColumns, groupColumns])

    const handleChange = (event) => {
        const value = event.target.value;
        if (value[value.length - 1] === "all") {
            setSelected(selected.length === options.length ? [] : options);
            return;
        }
        setSelected(value);

    };

    return (

        <div className={classes.root} style={{ minWidth: "100%"}}>

            <Autocomplete
                multiple
                id="tags-outlined"
                options={options}
                getOptionLabel={(option) => option}
                defaultValue={[]}
                disableCloseOnSelect={true}
                filterSelectedOptions
                value={selected}

                onChange={
                    (event, value) => {
                        setSelected(value)
                        console.log(value)
                    }
                }
                renderInput={(params) => (

                    <TextField

                        {...params}
                        variant="outlined"
                        label={placeholder}
                        style={{width:"60%"}}
                    />
                )
                }
            />

        </div>
    );
}

export default MultiSelectJoin;
