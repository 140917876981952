import React, { useEffect, useState } from "react";
import { Grid, Paper } from "@material-ui/core";
import MultiSelectJoin from "./MultiSelectJoin";

export default React.memo(function GroupingJoin({
  tables,
  groupColumns,
  setGroupColumns,
  availableColumns,
}) {
  const [columns, setColumns] = useState({});

  useEffect(() => {
    setColumns(availableColumns);
  }, [availableColumns]); // Update only when availableColumns prop changes

  return (
    <Paper style={{ color: "gray", width: "100%", marginTop: "20px" }}>
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        alignItems="center"
      >
        <h3>Grouping Columns</h3>

        {columns.length > 0 &&
          columns.map((table, i) => (
            <Grid key={i} item style={{ marginBottom: "10px" }}>
              <MultiSelectJoin
                length={table.columns.length}
                groupColumns={groupColumns}
                setGroupColumns={setGroupColumns}
                id={i}
                placeholder={table.table}
                options={table.columns}
              />
            </Grid>
          ))}
      </Grid>
    </Paper>
  );
})
