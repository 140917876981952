import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import data from '../allCategories.json';
import FilteredTable from "./FilteredTable ";

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    border: '1px solid lightgray',
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
  },
  title: {
    fontSize: '1.5em',
    fontWeight: 'bold',
    color: 'blueviolet',
    marginBottom: theme.spacing(2),
  },
  tree: {
    flexGrow: 1,
    width: '100%',
  },
  checkboxContainer: {
    marginLeft: 20,
  },
  submitButton: {
    marginTop: 20,
  },
}));

const renderVariables = (variables, checkedItems, handleCheck, availableVariables) => {
  const classes = useStyles();
  return (
    <div className={classes.checkboxContainer}>
      {variables.map((variable) => {
        const variableId = `variable-${variable.variableName}`;
        const isChecked = checkedItems[variableId] || false;
        const isAvailable = availableVariables.includes(variable.variableName);
        return (
          <FormControlLabel
            key={variable.variableId}
            control={
              <Checkbox
                checked={isChecked && isAvailable}
                onChange={() => isAvailable && handleCheck(variableId)}
                name={variable.variableName}
                disabled={!isAvailable}
              />
            }
            label={
              <span style={{ color: isAvailable ? 'blue' : 'gray' }}>
                {variable.variableName}
              </span>
            }
          />
        );
      })}
    </div>
  );
};

const renderTree = (nodes, checkedItems, handleCheck, availableVariables) => {
  if (!nodes || !nodes.data) return null;

  const nodeId = nodes.data.categoryName ? nodes.data.categoryName : nodes.data;
  const label = nodes.data.categoryName || nodes.data;
  const isChecked = checkedItems[nodeId] || false;

  return (
    <TreeItem
      key={nodeId}
      nodeId={nodeId}
      label={
        <FormControlLabel
          control={<Checkbox checked={isChecked} onChange={() => handleCheck(nodeId)} />}
          label={label}
        />
      }
    >
      {nodes.data.variables ? renderVariables(nodes.data.variables, checkedItems, handleCheck, availableVariables) : null}
      {Array.isArray(nodes.children) && nodes.children.length > 0 ? nodes.children.map((node) => renderTree(node, checkedItems, handleCheck, availableVariables)) : null}
    </TreeItem>
  );
};

export default function ProtocolTree({ availableVariables, metadataSelectedVariables, setMetadataSelectedVariables, tableColumns }) {
  const classes = useStyles();
  const [checkedItems, setCheckedItems] = useState({});
  const [selectedVariables, setSelectedVariables] = useState([]);
  const [metadataSubmitted, setMetadataSubmitted] = useState(false)



  const handleCheck = (nodeId) => {
    setCheckedItems((prevCheckedItems) => {
      const newCheckedItems = { ...prevCheckedItems };
      const isChecked = !newCheckedItems[nodeId];

      const checkNodeAndChildren = (node, isChecked) => {
        const currentNodeId = node.data.categoryName ? node.data.categoryName : node.data;
        newCheckedItems[currentNodeId] = isChecked;
        if (node.data.variables) {
          node.data.variables.forEach((variable) => {
            const variableId = `variable-${variable.variableName}`;
            if (availableVariables.includes(variable.variableName)) {
              newCheckedItems[variableId] = isChecked;
            } else {
              newCheckedItems[variableId] = false; // Ensure it stays unchecked
            }
          });
        }
        if (node.children) {
          node.children.forEach((child) => checkNodeAndChildren(child, isChecked));
        }
      };

      // Toggle the clicked node
      newCheckedItems[nodeId] = isChecked;

      // Find the node in the data and toggle all its children
      const findNodeAndCheckChildren = (node, nodeIdToFind) => {
        const currentNodeId = node.data.categoryName ? node.data.categoryName : node.data;
        if (currentNodeId === nodeIdToFind) {
          checkNodeAndChildren(node, isChecked);
        } else if (node.children) {
          node.children.forEach((child) => findNodeAndCheckChildren(child, nodeIdToFind));
        }
      };

      findNodeAndCheckChildren(data, nodeId);

      // Update selected variables state
      const newSelectedVariables = Object.keys(newCheckedItems)
        .filter(key => newCheckedItems[key] && key.startsWith('variable-'))
        .map(key => key.replace('variable-', ''));
      setSelectedVariables(newSelectedVariables);

      return newCheckedItems;
    });
  };

  const handleSubmit = () => {
    console.log('Selected Variables:', selectedVariables);
    console.log("table columns:", tableColumns)
    setMetadataSelectedVariables(selectedVariables)
    setMetadataSubmitted(true)
  };

  return (
    <div className={classes.container}>
      <div className={classes.title}>Metadata Manager</div>
      <TreeView
          className={classes.tree}
          defaultCollapseIcon={<ExpandMoreIcon/>}
          defaultExpanded={['root']}
          defaultExpandIcon={<ChevronRightIcon/>}
      >
        {renderTree(data, checkedItems, handleCheck, availableVariables)}
      </TreeView>
      <Button
          variant="contained"
          color="primary"
          className={classes.submitButton}
          onClick={handleSubmit}
      >
        Select variables
      </Button>
      {(metadataSubmitted && selectedVariables.length>0) && <FilteredTable tableColumns={tableColumns} selectedVariables={selectedVariables}/>}
    </div>
  );
}
