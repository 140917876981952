import React, {useEffect, useState} from 'react';
import {Button, Checkbox, FormControl, FormControlLabel, Grid, Paper, TextField} from '@material-ui/core';
import Filter from "./Filter";
import JoinFilter from "./JoinFilter";

export default function Filters({token, table, join, where, setWhere, joinTables, whereClause, setWhereClause, placeHolder, availableColumns}) {
    const [filterCount, setFilterCount] = useState(0)
    const [checked, setChecked] = useState(false)


    const handleChange = (event) => {
        setChecked(event.target.checked);
        handleClear()
    };

    const handleTextChange = (event) => {
        setWhereClause(event.target.value)
    }

    const handleAddFilter = () => {
        let tempCount = filterCount
        setFilterCount(tempCount + 1)
    }
    const handleClear = () => {
        setFilterCount(0)
        setWhere([])
    }

    useEffect(() => {
        setFilterCount(0)
        setWhere([])
    }, [joinTables, setWhere])

    useEffect(() => {
        if (table !== '') {
            setFilterCount(0)
            setWhere([])
        }
    }, [table, setWhere])

    return (<Paper style={{color: "gray", width: "100%", marginTop: "20px"}}>
        <Grid container direction="column"
              justifyContent="space-between"
              alignItems="center">
            <h3>Filters</h3>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={checked}
                        onChange={handleChange}
                        name="checkbox"
                        color="primary"
                    />
                }
                label="Advanced Filtering"
            />
            {!checked ? <>

                    {[...Array(filterCount)].map((e, i) =>
                        <Grid key={i} item xs={12}>
                            {join !== true ? (i===0 ? <Filter token={token} table={table} key={i} id={i} where={where} count={filterCount} setWhere={setWhere}/>
                                : <Filter token={token} table={table} key={i} id={i} where={where} count={filterCount} setWhere={setWhere} showLogicalOperator={true}/>)

                                :
                                <JoinFilter tables={joinTables} key={i} id={i} where={where} setFilterCount={setFilterCount}
                                            count={filterCount} availableColumns={availableColumns}
                                            setWhere={setWhere}/>}
                        </Grid>
                    )}


                    <Grid item sx={2}>
                        <Button onClick={handleAddFilter} variant="contained" color="primary"
                                style={{margin: "30px"}}>+Add
                            filter</Button>
                        <Button onClick={handleClear} variant="contained" color="secondary"
                                style={{margin: "30px"}}>Clear filters</Button>
                    </Grid>
                </>
                :
                <TextField style={{width: "90%", marginBottom: "20px"}}
                           id="outlined-multiline-static"
                           label="Where clause"
                           multiline
                           onChange={handleTextChange}
                           minRows={2}
                           placeholder={placeHolder}
                           variant="outlined"
                           fullWidth={true}
                />
            }

        </Grid>
    </Paper>)
}