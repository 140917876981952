import React, {useEffect, useState} from 'react';
import {MenuItem, FormControl, InputLabel, Select, Grid} from '@material-ui/core';


export default function AggregationColumn({options, aggregationColumns, setAggregationColumns, id}){
    const [columnName, setColumnName] = useState('')
    const [aggregation, setAggregation] = useState('')

    const formStyle = {
        margin: "1px",
        minWidth: "120",
    }

    useEffect(() => {
        setColumnName('')
        setAggregation('')
    }, [options])

    useEffect(() => {
        let tempAggregationColumns = aggregationColumns
        tempAggregationColumns[id] = {columnName, aggregation}
        setAggregationColumns(tempAggregationColumns)
    }, [columnName, aggregation, setAggregationColumns, aggregationColumns, id])

    return (
        <Grid spacing={2} container direction="row"
              justifyContent="space-between"
              alignItems="center">
            <Grid style={{marginTop:"5px"}} item xs={4}>
                <FormControl fullWidth variant="outlined" style={formStyle}>
                    <InputLabel htmlFor="label-column1">Column</InputLabel>
                    <Select
                        style={{minWidth: "250px"}}
                        label="Column"
                        labelId="label-column1"
                        value={columnName}
                        onChange={(e) => setColumnName(e.target.value)}>
                        {options.map((option, i) => <MenuItem value={option} key={i}>{option}</MenuItem>)}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={4}>
                <Grid item xs={4}>
                    <FormControl fullWidth variant="outlined" style={formStyle}>
                        <InputLabel htmlFor="label-column2">Aggregation</InputLabel>
                        <Select
                            style={{minWidth: "150px"}}
                            labelId="label-column2"
                            label="Column"
                            value={aggregation}
                            onChange={(e) => setAggregation(e.target.value)}>
                            <MenuItem value="MAX">MAX</MenuItem>
                            <MenuItem value="MIN">MIN</MenuItem>
                            <MenuItem value="AVG">AVG</MenuItem>
                            <MenuItem value="SUM">SUM</MenuItem>
                            <MenuItem value="COUNT">COUNT</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </Grid>)
}