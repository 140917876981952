import React, {useEffect, useState} from 'react';
import {Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField} from "@material-ui/core";

const formStyle = {
    margin: "1px",
    minWidth: "120",
};

const ColumnSelect = ({columns, filterColumn, setFilterColumn}) => (
    <FormControl fullWidth variant="outlined" style={formStyle}>
        <InputLabel htmlFor="table-label1">Column</InputLabel>
        <Select
            style={{width: "150px"}}
            labelId="table-label1"
            label="Column"
            value={filterColumn}
            onChange={(e) => setFilterColumn(e.target.value)}
        >
            {columns.length > 0 &&
                columns.map((column, i) => (
                    <MenuItem value={column} key={i}>
                        {column}
                    </MenuItem>
                ))}
        </Select>
    </FormControl>
);

const ConditionSelect = ({condition, setCondition}) => (
    <FormControl fullWidth variant="outlined" style={formStyle}>
        <InputLabel htmlFor="table-label2">Condition</InputLabel>
        <Select
            style={{width: "150px"}}
            labelId="table-label2"
            label="Condition"
            value={condition}
            onChange={(e) => setCondition(e.target.value)}
        >
            <MenuItem value="=">Equal to</MenuItem>
            <MenuItem value=">">Greater than</MenuItem>
            <MenuItem value="<">Less than</MenuItem>
            <MenuItem value="= DATE ">on (DATE)</MenuItem>
            <MenuItem value="> DATE ">After (DATE) </MenuItem>
            <MenuItem value="< DATE ">Before (DATE) </MenuItem>
        </Select>
    </FormControl>
);

const ValueInput = ({value, setValue}) => (
    <FormControl fullWidth variant="outlined" style={formStyle}>
        <TextField
            style={{width: "150px"}}
            variant="outlined"
            label="Value"
            value={value}
            onChange={(e) => setValue(e.target.value)}
        ></TextField>
    </FormControl>
);


export default function FilterSources({tables, filter, setFilter, options}) {
    const [condition, setCondition] = useState('')
    const [value, setValue] = useState('')
    const [filterColumn, setFilterColumn] = useState("")

    const filtering = (column, condition, value) => {
        if (column == "" || value == "" || filterColumn == "")
            return
        else {
            console.log(column, condition, value)
            setFilter({
                column, condition, value, 'table': tables[0].split("___")[0]
            })
        }

    }

    const reset = () => {
        setFilter({})
        setValue("")
        setCondition("")
        setFilterColumn("")
    }

    return (
        <Grid
            container
            style={{marginTop: "1px"}}
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="center"
        >


            <Grid item xs={4}>
                <ColumnSelect
                    columns={options}
                    filterColumn={filterColumn}
                    setFilterColumn={setFilterColumn}
                />
            </Grid>
            <Grid item xs={4}>
                <ConditionSelect condition={condition} setCondition={setCondition}/>
            </Grid>
            <Grid item xs={4}>
                <ValueInput value={value} setValue={setValue}/>
            </Grid>
            <Grid item xs={6}>
                <Button
                    variant="contained"
                    color="primary"
                    style={{margin: '20px'}}
                    onClick={() => filtering(filterColumn, condition, value)}>
                    Filter
                </Button>
            </Grid>
            <Grid item style={{marginTop: "10px"}} xs={6}>
                <Button
                    variant="contained"
                    color="secondary"
                    style={{margin: '20px'}}
                    onClick={() => reset()}>
                    Reset sources
                </Button>
            </Grid>
        </Grid>
    );
}