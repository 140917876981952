import React, {useEffect, useState} from 'react';
import {MenuItem, FormControl, InputLabel, Select, Grid, TextField} from '@material-ui/core';
import {getColumns} from "../utils";


export default function JoinFilter({where, setWhere, setFilterCount, count, id, tables, availableColumns}) {
    const [filterTable, setFilterTable] = useState('')
    const [filterColumn, setFilterColumn] = useState('')
    const [condition, setCondition] = useState('')
    const [value, setValue] = useState('')
    const [columns, setColumns] = useState([])
    const [operator, setOperator] = useState(id!==0 ? "AND" : "")

    useEffect(() => {
        const selectedTable = availableColumns.find(
            (item) => item.table === filterTable
        );
        setColumns(selectedTable ? selectedTable.columns : []);
    }, [tables, filterTable]);

    useEffect(() => {
        setWhere([])
    }, [tables, setWhere])


    const formStyle = {
        margin: "1px",
        minWidth: "120",
    }


    useEffect(() => {
        if (count === 0) {
            setCondition('')
            setValue('')
            setFilterColumn('')
            setFilterTable('')
        }
    }, [count])

    useEffect(() => {
        let tempWhere = where
        tempWhere[id] = {filterColumn, condition, value, filterTable, operator}
        setWhere(tempWhere)
    }, [filterColumn, condition, value, setWhere, where, id, filterTable, operator])

    const OperatorInput = (id !== 0) && (
        <Grid item xs={2}>
            <FormControl fullWidth variant="outlined" style={formStyle}>
                <InputLabel htmlFor="join-condition">Operator</InputLabel>
                <Select
                    style={{minWidth: "150px"}}
                    labelId="join-condition"
                    value={operator}
                    onChange={(e) => {
                        setOperator(e.target.value)
                    }}
                >
                    <MenuItem value="AND">AND</MenuItem>
                    <MenuItem value="OR">OR</MenuItem>
                    <MenuItem value="AND not">AND NOT</MenuItem>
                </Select>
            </FormControl>
        </Grid>
    );

    return (<>{tables.length > 0 &&
    <Grid container style={{marginTop: "1px"}} spacing={4} direction="row"
          justifyContent="space-between"
          alignItems="center">
        {filterTable !== "" && OperatorInput}
        <Grid item xs={filterTable ? 2 : 9}>
            <FormControl fullWidth variant="outlined" style={formStyle}>
                <InputLabel htmlFor="table-label">Table</InputLabel>
                <Select
                    style={{minWidth: "150px"}}
                    labelId="table-label"
                    value={filterTable}
                    label="Table"
                    inputProps={{
                        name: 'table',
                        id: 'table-label',
                    }}
                    onChange={(e) => setFilterTable(e.target.value)}>
                    {tables.length > 0 && tables.map((table, i) =>
                        <MenuItem value={table} key={i}>{table}</MenuItem>
                    )}
                </Select>
            </FormControl>
        </Grid>
        <Grid item xs={2}>
            {(columns !== undefined && filterTable !== '') &&
            <FormControl fullWidth variant="outlined" style={formStyle}>
                <InputLabel htmlFor="table-label1">Column</InputLabel>
                <Select
                    style={{minWidth: "150px"}}
                    labelId="table-label1"
                    label="Column"
                    value={filterColumn}
                    onChange={(e) => setFilterColumn(e.target.value)}>
                    {columns.map((column, i) => <MenuItem key={i} value={column}>{column}</MenuItem>)}
                </Select>
            </FormControl>}
        </Grid>
        <Grid item xs={2}>
            {(columns !== undefined && filterTable !== '') &&
            <FormControl fullWidth variant="outlined" style={formStyle}>
                <InputLabel htmlFor="table-label2">Condition</InputLabel>
                <Select
                    style={{minWidth: "150px"}}
                    labelId="table-label2"
                    label="Column"
                    value={condition}
                    onChange={(e) => setCondition(e.target.value)}>
                    <MenuItem value="=">Equal to</MenuItem>
                    <MenuItem value=">"> Greater than </MenuItem>
                    <MenuItem value="<">Less than </MenuItem>
                    <MenuItem value="= DATE ">on (DATE)</MenuItem>
                    <MenuItem value="> DATE ">After (DATE) </MenuItem>
                    <MenuItem value="< DATE ">Before (DATE) </MenuItem>
                </Select>
            </FormControl>}

        </Grid>
        <Grid item xs={2}>
            {(columns !== undefined && filterTable !== '') &&
            <FormControl fullWidth variant="outlined" style={formStyle}>
                <TextField
                    style={{minWidth: "130px"}}
                    variant="outlined"
                    label="Value"
                    value={value}
                    onChange={(e) => setValue(e.target.value)}>
                </TextField>
            </FormControl>}
        </Grid>
    </Grid>}
    </>)
}